import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import { FaFingerprint, FaPencilRuler, FaDraftingCompass} from 'react-icons/fa';
import {RiRoadMapLine, RiPencilRuler2Line} from 'react-icons/ri';
import {GoOrganization} from 'react-icons/go';
import {BsPersonCheck} from 'react-icons/bs';
import {GrShieldSecurity} from 'react-icons/gr';
import {BsQuestionSquare} from 'react-icons/bs';
import {BiShieldQuarter, BiConversation} from 'react-icons/bi';
//import {GiPoliceOfficerHead, GiPencil} from 'react-icons/gi';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import { Link } from "gatsby"


import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>How can we help?</h2>
            <h5 className={classes.description}>
              A2g Cyber can support your organisation with a range of cyber security advice and guidance. Read on for some examples, and please do <Link to="#contact">reach out</Link> if you are looking for something different.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="CISO Advisory"
                description="Offering a 'sounding board' for a CISO, or Head of IT, to have regular sessions with. Our breadth of experiences across multiple industries and sectors can support your decision making. Our actionable approach can help you make better use of already stretched resources in your team and wider IT."
                icon={BiConversation}
                iconColor="black"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Enterprise Security Strategy"
                description="Organisations often have unused technology, overlapping capability, unsolved challenges and quick wins to be had. We are driven by making better use of existing investments, whilst looking to achieve frictionless security. Our approach is to work with your teams to get a good understanding of your as-is and future aspirations."
                icon={RiRoadMapLine}
                iconColor="black"
                vertical
              />
            </GridItem>
          <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Identity and Secure Remote Working"
                description="Specialising in Zero Trust implementations, Microsoft Azure AD, Multi-Factor Authentication (MFA) and Office 365. We have been in the driving seat to roll out MFA and Zero Trust architectures at scale in organisations. We have also solved complex B2B external collaboration challenges, across on-premise and cloud-based identities."
                icon={BsPersonCheck}
                iconColor="black"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Security Architecture Design Reviews"
                description="Independent security architecture reviews of products or services summarised into an easy-to-understand report with prioritised recommendations. We understand security from the fundamentals, helping us apply knowledge and principals to highly complex designs"
                icon={RiPencilRuler2Line}
                iconColor="black"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="Security Monitoring"
                  description={["A2g Cyber has had experience advising a range of organisations on how to improve their security monitoring capability. From logging advice (experience gained from founding the ", <Link to='https://www.ncsc.gov.uk/blog-post/logging-made-easy'> NCSC Logging Made Easy project</Link>, "), to supporting security engineering work to automate SOC activities."]}
                  icon={BiShieldQuarter}
                  iconColor="black"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="... and more"
                  description="Looking for something not covered? Why not contact us and see if we can tailor to your requirements? If we cannot fully meet your needs, there may be a company/individual in our extensive network of security and technology professionals that could!"
                  icon={BsQuestionSquare}
                  iconColor="black"
                  vertical
                />
              </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
