/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "gatsby";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// React icons
//import { FaTwitter, FaFacebook, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import Email from "@material-ui/icons/Email";
import Logo from "assets/img/website_logo_transparent_background.png"

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";
function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
      <Tooltip
          id="email"
          title="Email us"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
        <Button color="transparent" className={classes.navLink} href="#contact">
          <Email className={classes.icons} />
          Email
        </Button>
        </Tooltip>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        {/*<Tooltip
          id="instagram-twitter"
          title="Follow us on Twitter"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/shane_cyber"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <FaTwitter/>
            Twitter
          </Button>
        </Tooltip>*/}
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="linkedin"
          title="Follow us on LinkedIn"
          placement={typeof window !== 'undefined' && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://www.linkedin.com/in/shane-m-712019104/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <FaLinkedinIn/>
            LinkedIn
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
