import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import logo from "assets/img/website_logo_transparent_background.png"
import aboutStyle from "assets/jss/material-kit-react/views/landingPageSections/aboutStyle.jsx";

import shane from "assets/img/profile.jpg";
import bristol from "assets/img/bristol.jpg";
import govt from "assets/img/govt.jpg";

class AboutUsSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRounded,
      classes.imgFluid
    );
    const imageClassesSq = classNames(
      classes.imgRaised,
      classes.imgRoundedSquare,
      classes.imgFluid
    );
    const imageClassesSq2 = classNames(
      classes.imgRoundedSquare,
      classes.imgFluid
    );
    return (
      <div className={classes.section}>
        <img src={logo} alt="A2g Cyber Logo" className={imageClassesSq2} style={{ width: "200px"}}/>
       <h2 className={classes.title}>About Us</h2>
        <div>
          <GridContainer justify="center">
            <GridItem  xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                  <img src={shane} alt="Shane M" className={imageClassesSq} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Shane Mallia
                  <br />
                  <small className={classes.smallTitle}>Principal Consultant &amp; Founder</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Shane brings a mix of leadership, vision and consultancy skills fused with hands-on technical knowledge. He is outcome driven, and strives to provide actionable advice to tangibly improve security in your organisation.
                    In recent years, Shane has been focusing on Zero Trust architectures to support FTSE100's &amp; Government with enterprise strategy, secure collaboration improvements and tech leadership of enterprise security projects.            
                  </p>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                    href="https://www.linkedin.com/in/shane-m-712019104/"
                    target="_blank"
                  >
                    <FaLinkedin/>
                  </Button>
                  {/*<Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                    href="https://twitter.com/shane_cyber"
                    target="_blank"
                  >
                    <FaTwitter/>
                  </Button>*/}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem  xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                  <img src={govt} alt="UK Government" className={imageClassesSq} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Our Background
                  <br />
                  <small className={classes.smallTitle}>Ten years in Government Security &amp; leading a Global Enterprise Security team.</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                  Our experiences have been drawn from ten years in UK Government, leading security architecture advice &amp; guidance engagements across a wide range of high-profile clients. We also have experience leading a global Enterprise Security team. This helps round off 'world-class' security knowledge with understanding the day-to-day 'real-world' challenges in an IT department - resulting in achievable, threat-led advice.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem  xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                  <img src={bristol} alt="Bristol Templemeads" className={imageClassesSq} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Based in Bristol
                  <br />
                  <small className={classes.smallTitle}>Close to Cheltenham, Gloucester, Cardiff, Bath, Swindon and Reading</small>
                </h4>

                <CardBody>
                  <p className={classes.description}>
                      Bristol (and the West Country in general) hosts a range of Defence, Fintech, Manufacturing and Technology startups companies - and is an ideal location for A2g Cyber. The majority of our delivery can be provided remotely, but we also can attend in-person where beneficial - for example hosting workshops.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(aboutStyle)(AboutUsSection);
