import React, { useState, useEffect } from "react";
import axios from 'axios';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";


class WorkSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        successName: false, 
        errorName: false,
        
        errorEmail: false,
        successEmail:false,

        successQuery: false, 
        errorQuery: false,
      
        buttonColour: "primary",
        submitDisabled: false,
        btnTxt: "Submit Message"};
  }

    handleChange = (event) => {
        if (event.target.id == "name")
        {
          if (event.target.value.length == 0)
          {
            this.setState({ errorName: false, successName: false});
          }
          else if (event.target.value.length > 2) {
            this.setState({ errorName: false, successName: true});
          } else {
            this.setState({ errorName: true, successName: false});
          }
        }
        if (event.target.id == "query")
        {
          if (event.target.value.length == 0)
          {
            this.setState({ errorQuery: false, successQuery: false});
          }
          else if (event.target.value.length > 2) {
            this.setState({ errorQuery: false, successQuery: true});
          } else {
            this.setState({ errorQuery: true, successQuery: false});
          }
        }

        if (event.target.id == "email")
        {
          const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (event.target.value.length == 0 )
          {
            this.setState({ errorEmail: false, successEmail: false });
          }
          else if (event.target.value.length > 2) {
            if (regexp.test(event.target.value)){
              this.setState({ errorEmail: false, successEmail: true });
            }else {
            this.setState({ errorEmail: true, successEmail: false });
            }
          }
          else
          {
            this.setState({ errorEmail: true, successEmail: false });
          }
          
        }
    }

    validateCheck ()
    {
      if (this.state.successEmail && this.state.successName && this.state.successQuery)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    handleSubmit = (e) => {
      e.preventDefault();
      
      if (this.validateCheck())
      {
        this.setState({buttonColour: "warning", submitDisabled: true, btnTxt: "Sending..."});

          const messageVar = { email: e.target.email.value, name: e.target.name.value, query: e.target.query.value };
          axios.post('/api/send_mail', messageVar).then (res => {
            this.setState({buttonColour: "success", btnTxt: "Success! Copy of email sent to "+messageVar.email});
          }).catch(err => {
            if (err.response) {
              this.setState({buttonColour: "danger", btnTxt: "Error - please email info@a2g-cyber.com"});
            } else if (err.request) {
              this.setState({buttonColour: "danger", btnTxt: "Error - please email info@a2g-cyber.com"});
            } else {
              this.setState({buttonColour: "danger", btnTxt: "Error - please email info@a2g-cyber.com"});
            }
        })
      }
      else
      {
        this.setState({buttonColour: "info", btnTxt: "Check fields & try again"});
      }
    }


  render() {
    const { classes } = this.props;
    return (


      <div className={classes.section} id="contact">
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Contact us</h2>
            <h4 className={classes.description}>
              If you would like to know more, or have some questions, then please fill out the form below.

              We aim to respond within one to two working days.
            </h4>
          
            <form onSubmit={this.handleSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Name"
                    id="name"
                    name="name"
                    autoComplete="name"
                    required
                    error={this.state.errorName}
                    success={this.state.successName}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onBlur: (e) => this.handleChange(e)
                    }}
                  />
                </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                      labelText="Your Email"
                      id="email"
                      name="email"
                      required
                      autoComplete="email"
                      error={this.state.errorEmail}
                      success={this.state.successEmail}

                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onBlur: (e) => this.handleChange(e)
                      }}
                    />               
                  </GridItem>
                <CustomInput
                  labelText="Your Query"
                  id="query"
                  name="query"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.textArea
                  }}
                  required
                  error={this.state.errorQuery}
                  success={this.state.successQuery}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    onBlur: (e) => this.handleChange(e)
                  }}
                  
                /> 
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.textCenter}
                  >
                    <Button type="submit" color={this.state.buttonColour} disabled={this.state.submitDisabled}> {this.state.btnTxt} </Button>
                  </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(workStyle)(WorkSection);
